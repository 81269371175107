import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Section, Span, Text, Title } from "../../components/Core";
import { CITY, COMPANY_NAME, EMAIL, KVK, PHONE, STREET, WHATSAPP } from "../../constants/CompanyConstants";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/Forms/ContactForm";

const ContactCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  margin-top: 3rem;
  box-shadow: ${({ theme }) => `0 20px 40px ${rgba(theme.colors.secondary, 0.1)}`};
`;

const Contact = ({ hero = true, bg = "dark", ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <Box className="pr-lg-5">
                <Title color="light" variant="secSm" mb="2rem">
                  {t("contact_title")}
                </Title>
                <Text color="light">{t("contact_description")}</Text>
                <Text
                  color="light"
                  mb="2.75rem"
                  css={`
                    font-size: 11px;
                    line-height: normal;
                    letter-spacing: normal;
                  `}
                >
                  {t("contact_fine_print")}
                </Text>
                <ContactForm theme="dark" />
              </Box>
            </Col>
            <Col lg="5" className={"align-self-center"}>
              <ContactCard className="p-5 ml-lg-5">
                <div className={"mb-4"}>
                  <Title color="light" variant="card" mb="1rem">
                    {COMPANY_NAME}
                  </Title>
                  <Title color="light" variant="cardSm">
                    {STREET}
                  </Title>
                  <Title color="light" variant="cardSm">
                    {CITY}
                  </Title>
                  <Title color="light" variant="cardSm">
                    {t("kvk")}: {KVK}
                  </Title>
                </div>
                <div>
                  <Text color="light">{t("mail_to")}</Text>
                  <a href={`mailto:${EMAIL}`} className="font-weight-bold">
                    <Span color="primary">{EMAIL}</Span>
                  </a>
                </div>
                <div className="mt-3">
                  <Text color="light">{t("call_us")}</Text>
                  <div>
                    <a href={`tel:${PHONE}`} className="font-weight-bold">
                      <Span color="primary">{PHONE}</Span>
                    </a>
                  </div>
                </div>
                <div className="mt-3">
                  <Text color="light">{t("send_a_whatsapp")}</Text>
                  <div>
                    <a href={`https://wa.me/${WHATSAPP}`} className="font-weight-bold" target={"_blank"}>
                      <Span color="primary">{WHATSAPP}</Span>
                    </a>
                  </div>
                </div>
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
