import styled from "styled-components";
import { Form } from "antd";

const FormStyled = styled(Form)`
  .ant-input {
    background-color: ${({ theme }) => theme.colors.dark} !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    height: calc(1.5em + 1.5rem + 2px);
    color: ${({ theme }) => theme.colors.light} !important;
    border-radius: 0;

    &:focus,
    :hover {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.lightShade};
    }
  }

  label {
    color: ${({ theme }) => theme.colors.lightShade};
  }
`;

export default FormStyled;
