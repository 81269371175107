import React, { useState } from "react";
import { Input, message } from "antd";
import { Button, ButtonIcon, Form } from "../Core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { contactTopics } from "../../data/contact";
import { sendContactMail } from "../../functions/API/contact";
import { saveEventToAnalytics } from "../../functions/GooglePlatform/GoogleAnalyticsEvents";
import { SUBMIT_CONTACT_FORM } from "../../constants/GoogleAnalyticsConstants";

const TopicButton = styled(Button)`
  padding: 8px 15px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: ${({ theme, active }) => (!active ? "transparent" : theme.colors.secondary)};

  &:hover {
    transform: translateY(-2px);
  }
`;

const { TextArea } = Input;

const ContactForm = ({ theme = "dark", ...rest }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState(contactTopics[0].key);
  const key = "message";

  const onPressTopic = (e, key) => {
    e.preventDefault();
    setTopic(key);
  };

  const sendMail = () => {
    form.validateFields().then((fields) => {
      fields.topic = contactTopics[topic].item;
      fields.phone = fields.phone || null;
      setIsLoading(true);
      message.loading({ content: t("send") + "...", key });
      sendContactMail(fields)
        .then((resp) => {
          if (!resp.ok) {
            message.error({ content: t("notifications.contact_failed"), key, duration: 4 });
          } else {
            form.resetFields();
            message.success({ content: t("notifications.contact_sent"), key, duration: 4 });
          }
          setIsLoading(false);
          saveEventToAnalytics({
            eventName: SUBMIT_CONTACT_FORM,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          message.error({ content: t("notifications.contact_failed"), key, duration: 4 });
          console.error(err);
        });
    });
  };

  return (
    <Form
      form={form}
      onFinish={() => sendMail()}
      scrollToFirstError={{ behavior: "smooth", block: "inline" }}
      layout="vertical"
      {...rest}
    >
      <div className={"mt-4"}>
        <Form.Item name="topic">
          {contactTopics.map((item) => {
            return (
              <TopicButton key={item.key} onClick={(e) => onPressTopic(e, item.key)} active={topic === item.key}>
                {item.label}
              </TopicButton>
            );
          })}
        </Form.Item>
      </div>
      <div className="mt-4">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: t("form.enter_name"),
            },
          ]}
        >
          <Input placeholder={t("first_last_name")} />
        </Form.Item>
      </div>
      <div className="mt-4">
        <Form.Item name="company">
          <Input placeholder={t("company")} />
        </Form.Item>
      </div>
      <div className="mt-4">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("form.enter_email"),
            },
            {
              type: "email",
              message: t("form.enter_valid_email"),
            },
          ]}
        >
          <Input placeholder={t("email")} />
        </Form.Item>
      </div>
      <div className="mt-4">
        <Form.Item name="phone">
          <Input placeholder={t("phone_number")} />
        </Form.Item>
      </div>

      <div className="mt-4 ">
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: t("form.enter_message"),
            },
          ]}
        >
          <TextArea rows={4} placeholder={contactTopics[topic].info} maxLength={220} />
        </Form.Item>
      </div>
      <div className="mt-4 mt-lg-5">
        <ButtonIcon icon={"icon-send-2"} variant="primary" type="submit" isLoading={isLoading} style={{ fontSize: 14 }}>
          {t("send")}
        </ButtonIcon>
      </div>
    </Form>
  );
};

export default ContactForm;
