import React from "react";
import { Container } from "react-bootstrap";
import { Title } from "../Core";
import styled from "styled-components";
import Social from "../Social";
import { COMPANY_SLOGAN, SOCIAL_MEDIA } from "../../constants/CompanyConstants";
import Logo from "../Logo";

const Footer = ({ isDark = true }) => {
  const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 125px;
    padding: 0 40px 40px 40px;
    font-size: 16px;
  `;

  const FooterItemsWrapper = styled.div`
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  `;

  return (
    <>
      <Footer>
        <Container fluid>
          <FooterItemsWrapper>
            <Logo small onClick={() => window.scrollTo(0, 0)} />
            <Title
              variant="card"
              css={`
                font-size: 20px;
                text-align: center;
                align-self: center;
                @media (max-width: 1024px) {
                  font-size: 16px;
                  margin: 16px 0;
                }
              `}
            >
              {COMPANY_SLOGAN}
            </Title>
            <Social
              items={SOCIAL_MEDIA}
              css={`
                align-self: center;
              `}
            />
          </FooterItemsWrapper>
        </Container>
      </Footer>
    </>
  );
};

export default Footer;
