import React from "react";
import { Box, Button } from "../Core";

const ButtonIcon = ({
  variant = "primary",
  afterText = false,
  color = "#fff",
  icon = "icon-chat-round-2",
  children,
  ...rest
}) => {
  return (
    <Button variant={variant} color={color} {...rest}>
      {!afterText && (
        <Box
          css={`
            font-size: 1.1875rem;
            padding-right: 1.25rem;
            display: flex;
          `}
        >
          <i className={`icon ${icon}`} />
        </Box>
      )}
      {children}
      {afterText && (
        <Box
          css={`
            font-size: 1.1875rem;
            padding-left: 1.25rem;
            display: flex;
          `}
        >
          <i className={`icon ${icon}`} />
        </Box>
      )}
    </Button>
  );
};

export default ButtonIcon;
